<template>
  <div class="message">
    <v-tabs background-color="#fff"
      color="#270645"
      height="40px"
      hide-slider
        v-model="select"
      >
      <v-tab  exact-path :to="{ name: 'Message' }">{{$t('messageMessage')}}</v-tab>
      <v-tab exact-path :to="{ name: 'Notifications' }">{{$t('messageNotifications')}}</v-tab>
      <v-tab exact-path :to="{ name: 'SystemAlert' }">{{$t('systemalert')}}</v-tab>
    </v-tabs>
     <div class="allRead" @click="allRead" v-if="isShow">{{$t('readAll')}}</div>
    <router-view class="mt-8"></router-view>
  </div>
</template>

<script lang='js'>
import api from "@/api";
export default {
  name: 'Message',
  props: {},
  data: function() {
    return {
        select:'',
      isShow:true
    };
  },
  computed: {
         isMobile: function() {
            return this.$vuetify.breakpoint.mobile;
         },
         handler: function () {
          return {
              activeTab: this.activeTab,
          }
        }
      },
      watch: {
        handler : {
          handler: function () {
          console.log('change')
        },
        deep: true
        },
         select(value){
          if(value == '/message/'){
            this.isShow = true
          }else{
             this.isShow = false
          }
          console.log("ssawqw",value)
        }
      },
        methods:{
       async allRead(){
         console.log("wqwq")
       await  api.home.readAllMessage({address:this.$store.state.did})
        //  location. reload()
          window.eventBus.$emit("allReadMessage");
        }
      
      }
};

</script>

<style lang='scss' scoped>
.message{
  position: relative;
  .allRead{
    position: absolute;
    right: 0;
    top:14px;
    color: #270645;
    font-size: 14px;
    cursor: pointer;
  }
  > .v-tabs{
    border-bottom: solid 1px #e3e3e3;

    .v-tab{
      padding: 0;
      margin-right: 50px;
     font-family:Helvetica;
      font-size: 25px;
      font-weight: bold;
      font-stretch: normal;
      line-height: 120px;
      letter-spacing: 0px;
      color: #270645;
    }
  }
}

</style>
